@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav-link {
  cursor: pointer;
  font-weight: bold;
  opacity: 0.9;
  max-width: 300px; /* Установите нужное значение */
  white-space: nowrap; /* Предотвращает перенос строк */
  overflow: hidden; /* Скрывает лишний текст */
  text-overflow: ellipsis; 
}

.nav-item > svg {
  cursor: pointer;
  font-weight: bold;
  opacity: 0.8;
  fill: white
}

.nav-link.active, .nav-link.active svg{
  opacity: 1;
}

.nav-link:hover, .nav-item:hover>svg {
  opacity: 1;
}

th {
  max-width: 200px!important;
}

tr {
  cursor: pointer;
}

td {
  font-size: 14px!important;
  font-weight: 500;
  max-width: 200px!important;
}

form .error-message {
  margin-top: 5px;
  color: red;
}

form .error-message::before {
  content: "*";
  margin-top: 5px;
}


#trash, #download, #add-file {
  width: 30px;
  height: 30px;
}

#trash:hover {
  fill: red;
}

#download:hover, #add-file:hover {
  fill: rgb(52, 133, 255);
}

.alert-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1050; /* Bootstrap's z-index for toasts */
}

.toast {
  margin-bottom: 0.75rem; /* Отступ между тостами */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 700px;
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
}

.modal-close {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background: #007bff;
  color: white;
}

.btn-secondary {
  background: #6c757d;
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
}

.pagination select {
  margin-left: 10px;
  padding: 5px;
}

.filters {
  cursor: pointer;
  transition: 0.3s ease-in;
}

.filters:hover {
  background: rgb(221, 221, 221);
}